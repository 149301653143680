export const environment = {
  production: true,
  disableLog: true,
  appName: 'Esomus [Sicli]',
  languages: ['fr', 'nl'],
  api: {
    domain: 'api.sicli.esomus.com',
    url: 'https://api.sicli.esomus.com/api'
  },
  version: '{BUILD_VERSION}'
};
